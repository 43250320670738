import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import * as apiUtils from "../../utils/apiUtils";
import Articles from "../Articles";
import CSPUpdates from "../CSPUpdates";
import CSPIncidents from "../CSPIncidents";
import CSPBlogs from "../CSPBlogs";
import TechBlogs from "../TechBlogs";
import CSPNotify from "../CSPNotify";
import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import CSPYoutube from "../CSPYoutube";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
  },
  tabs: {
    minWidth: 180,
    width: 180,
  },
});

function TabPanelSubPart(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{ height: "600px", width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class TabPanelSub extends Component {
  constructor(props) {
    super(props);
    this.state = { categories: [], value: 0, clickedMenu: "" };
  }

  async componentDidMount() {
    let categories = await apiUtils.kaciBackendRequest(
      "/category/subCategory?subCategory=" + this.props.category
    );
    this.setState({ categories });
  }

  render() {
    const { classes } = this.props;
    const { categories, value } = this.state;

    const items = [];
    const items1 = [];

    const histparam1 = this.props.match.params.id1;
    const histparam2 = this.props.match.params.id2;
    const blankSpaceRemovedParam = window.location.href
      .split("/")
      [window.location.href.split("/").length - 1].replace("%20", "")
      .toLowerCase();
    const decodedParam = decodeURIComponent(
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ]
    );

    for (const category of categories.entries()) {
      const blankSpaceRemovedParamConfirm = category[1].category
        .replace(" ", "")
        .toLowerCase();
      items.push(
        <Tab
          label={category[1].category}
          value={category[1].category}
          component={Link}
          to={`/${histparam1}/${category[1].category}`}
          onClick={() => {
            this.setState({ clickedMenu: category[1].category });
          }}
          className={
            decodedParam === category[1].category ||
            blankSpaceRemovedParam === blankSpaceRemovedParamConfirm ||
            window.location.href.includes(`/${category[1].category}`) ||
            this.state.clickedMenu === category[1].category
              ? "clicked"
              : ""
          }
        />
      );
    }

    for (const category of categories.entries()) {
      items1.push(
        <TabPanelSubPart value={histparam2} index={category[1].category}>
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <Articles category={category[1].category}></Articles>
          </Container>
        </TabPanelSubPart>
      );
    }

    return (
      <div className={`${classes.root} side-nav`}>
        <Tabs
          value={histparam2}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          orientation="vertical"
          className={`${classes.tabs} side-nav__items`}
        >
          {items}
        </Tabs>
        {items1}
      </div>
    );
  }
}

TabPanelSub.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(useStyles)(TabPanelSub));
