import React from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const dataJson = {
  id: "",
  article_thumbnail: "",
  article_image: "",
  insertion_date: "",
  category: "",
  article_title: "",
  article_href: "",
  article_source: "",
  article_body: "",
};
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    textAlign: "left",
  },
  media: {
    height: 0,
    paddingTop: "36.25%", // 16:9
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  let data = props.data ? props.data : dataJson;

  let content = "";
  if (data.article_body.length > 300) {
    content = data.article_body.substring(0, 300);
  } else {
    content = data.article_body;
  }

  let image_url = "";
  if (data.article_image == null) {
    image_url =
      "https://www.playnetwebhosting.com/wp-content/plugins/slider-hero-pro/images/noimage.png";
  } else {
    image_url = data.article_image;
  }

  return (
    <Card className={classes.root}>
      <CardHeader title={data.article_title} subheader={data.articleDate} />
      <CardMedia
        className={classes.media}
        image={image_url}
        title="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {content.concat("....")}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          onClick={() => {
            axios({
              method: "post",
              //url: 'http://localhost:8080/pubsub/article',
              url: global.config.endpoint + "/pubsub/article",
              data: data,
            });

            window.open(data.article_href, "_blank");
          }}
          aria-label="add to favorites"
          style={{ color: "#A50034" }}
        >
          <ExitToAppIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
