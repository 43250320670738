import axios from 'axios';

const Taogi_URL="https://gateway-an1-cnl-common-api-service-a7i4uz5g.an.gateway.dev";

export async function kaciBackendRequest(path) {
    let { data: datas} = await axios.get(global.config.endpoint + path);
    return datas;
}

export async function openApiRequest(url) {
    let { data: datas} = await axios.get(url);
    return datas;
}

export async function TaogiRequest(path) {
    let { data: datas} = await axios.get(Taogi_URL + path);
    return datas;
}