import React from "react";
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TabPanelSub from "./TabPanelSub";
import TabPanelSubCSP from "./TabPanelSubCSP";
import * as apiUtils from "../../utils/apiUtils";
import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import Alert from "@material-ui/lab/Alert";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{ height: "600px" }}
      {...other}
    >
      {value === index && (
        <Box style={{ paddingLeft: 0, paddingRight: 0 }} p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
});

class ScrollableTabsButtonAuto extends Component {
  constructor(props) {
    super(props);
    this.state = { clientip: "", value: 0 };
  }

  async componentDidMount() {
    let clientip = await apiUtils.kaciBackendRequest("/ip");

    this.setState({ clientip });
  }

  render() {
    const { classes } = this.props;
    const { clientip, value } = this.state;
    const histparam1 = this.props.match.params.id1;

    // console.log(clientip);

    const IPs = ["27.122.140.10", "165.243.5.159",
    "27.122.137.92",  // GDC NAT IP
    "13.209.162.175" //cnl nat ip(auto pdf download lambda)
  
  ];

    var isUcloudIP = false;

    for (const ip of IPs) {
      if (clientip.includes(ip)) {
        isUcloudIP = true;
        break;
      }
    }

    if (isUcloudIP) {
      return (
        <div className={`top-nav ${classes.root} `}>
          <Tabs
            value={histparam1}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            variant="scrollable"
            centered
          >
            <Tab
              label="신기술 뉴스"
              style={{ fontWeight: "bold" }}
              value="신기술 뉴스"
              component={Link}
              to="/신기술 뉴스/클라우드"
            />
            <Tab
              label="주요 회사 동향"
              style={{ fontWeight: "bold" }}
              value="주요 회사 동향"
              component={Link}
              to="/주요 회사 동향/CNCF"
            />
            <Tab
              label="MSP 동향"
              style={{ fontWeight: "bold" }}
              value="MSP 동향"
              component={Link}
              to="/MSP 동향/LG CNS"
            />
            <Tab
              label="CSP 동향"
              style={{ fontWeight: "bold" }}
              value="CSP 동향"
              component={Link}
              to="/CSP 동향/AWS"
            />
            <Tab
              label="AWS"
              style={{ fontWeight: "bold" }}
              value="aws"
              component={Link}
              to="/aws/update"
            />
            <Tab
              label="GCP"
              style={{ fontWeight: "bold" }}
              value="gcp"
              component={Link}
              to="/gcp/update"
            />
            <Tab
              label="Azure"
              style={{ fontWeight: "bold" }}
              value="azure"
              component={Link}
              to="/azure/update"
            />
            <Tab
              label="NCP"
              style={{ fontWeight: "bold" }}
              value="ncp"
              component={Link}
              to="/ncp/update"
            />
            <Tab
              label="Tech"
              style={{ fontWeight: "bold" }}
              value="tech"
              component={Link}
              to="/tech/blog"
            />
          </Tabs>
          {/* <TabPanel value={value} index={0}>     
          <iframe width="100%" height="600px" src="https://datastudio.google.com/embed/reporting/11f2ff10-933c-444b-b04b-652952de37b8/page/2kSBC" frameborder="0" allowfullscreen></iframe>
      </TabPanel> */}
          <TabPanel value={histparam1} index="신기술 뉴스">
            <TabPanelSub category="신기술 뉴스" tag="article"></TabPanelSub>
          </TabPanel>
          <TabPanel value={histparam1} index="주요 회사 동향">
            <TabPanelSub
              category="주요 회사/단체 동향"
              tag="article"
            ></TabPanelSub>
          </TabPanel>
          <TabPanel value={histparam1} index="MSP 동향">
            <TabPanelSub category="MSP 동향" tag="article"></TabPanelSub>
          </TabPanel>
          <TabPanel value={histparam1} index="CSP 동향">
            <TabPanelSub category="CSP 동향" tag="article"></TabPanelSub>
          </TabPanel>
          <TabPanel value={histparam1} index="aws">
            <TabPanelSubCSP category="AWS" tag="CSP"></TabPanelSubCSP>
          </TabPanel>
          <TabPanel value={histparam1} index="gcp">
            <TabPanelSubCSP category="GCP" tag="CSP_Incident"></TabPanelSubCSP>
          </TabPanel>
          <TabPanel value={histparam1} index="azure">
            <TabPanelSubCSP category="Azure" tag="blog"></TabPanelSubCSP>
          </TabPanel>
          <TabPanel value={histparam1} index="ncp">
            <TabPanelSubCSP category="NCP" tag="notify"></TabPanelSubCSP>
          </TabPanel>
          <TabPanel value={histparam1} index="tech">
            <TabPanelSubCSP category="Tech" tag="notify"></TabPanelSubCSP>
          </TabPanel>
        </div>
      );
    } else {
      return (
        <div className={`top-nav ${classes.root}`}>
          <Tabs
            value={histparam1}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            variant="scrollable"
            centered
          >
            <Tab
              label="신기술 뉴스"
              style={{ fontWeight: "bold" }}
              value="신기술 뉴스"
              component={Link}
              to="/신기술 뉴스/클라우드"
            />
            <Tab
              label="주요 회사 동향"
              style={{ fontWeight: "bold" }}
              value="주요 회사 동향"
              component={Link}
              to="/주요 회사 동향/CNCF"
            />
            <Tab
              label="MSP 동향"
              style={{ fontWeight: "bold" }}
              value="MSP 동향"
              component={Link}
              to="/MSP 동향/LG CNS"
            />
            <Tab
              label="CSP 동향"
              style={{ fontWeight: "bold" }}
              value="CSP 동향"
              component={Link}
              to="/CSP 동향/AWS"
            />
          </Tabs>
          <TabPanel value={histparam1} index="신기술 뉴스">
            <TabPanelSub category="신기술 뉴스" tag="article"></TabPanelSub>
          </TabPanel>
          <TabPanel value={histparam1} index="주요 회사 동향">
            <TabPanelSub
              category="주요 회사/단체 동향"
              tag="article"
            ></TabPanelSub>
          </TabPanel>
          <TabPanel value={histparam1} index="MSP 동향">
            <TabPanelSub category="MSP 동향" tag="article"></TabPanelSub>
          </TabPanel>
          <TabPanel value={histparam1} index="CSP 동향">
            <TabPanelSub category="CSP 동향" tag="article"></TabPanelSub>
          </TabPanel>
        </div>
      );
    }
  }
}

export default withStyles(useStyles)(ScrollableTabsButtonAuto);
