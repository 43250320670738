import React from "react";
import "../style/App.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import kaciicon from "../static/images/birdSmall.png";
import { makeStyles } from "@material-ui/core/styles";

function NavBar() {
  const classes = useStyles();

  return (
    // 수정한 부분(style={{ backgroundColor: '#A50034'}})
    <AppBar
      className="header"
      position="absolute"
      style={{ backgroundColor: "#fff" }}
    >
      <Toolbar className={`${classes.toolbar} logo-wrapper`}>
        <img src={kaciicon} alt="logo" />
        <p>KACI</p>
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({}));

export default NavBar;
