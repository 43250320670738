import React, { Component } from "react";
import * as apiUtils from "../utils/apiUtils";
import Grid from "@material-ui/core/Grid";
import ReactPaginate from "react-paginate";
import CSPBlogCard from "../components/Card/CSPBlogCard";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PDFDocumentTechBlogs from "../components/PDF/PDFDocumentTechBlogs";
import Typography from "@material-ui/core/Typography";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import subMonths from "date-fns/subMonths";
import addMonths from "date-fns/addMonths";
import subDays from "date-fns/subDays";
import { ko } from "date-fns/esm/locale";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const PER_PAGE = 4;

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updates: [],
      currentPage: 0,
      date: [],
      loading: true,
      currentDate: "",
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      lang: "ko",
      PDFloading: false,
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.handleLang = this.handleLang.bind(this);
    this.setPDFloading = this.setPDFloading.bind(this);
    this.getBlogData = this.getBlogData.bind(this);
  }

  async componentDidMount() {
    const start = moment(this.state.startDate)
      .format("yyyy-MM-DD 00:00:00")
      .toString();
    const end = moment(this.state.endDate)
      .format("yyyy-MM-DD 23:59:59")
      .toString();

    const updates = await this.getBlogData(start, end, this.state.lang);

    let dateList = [];
    for (var i = 0; i < 5; i++) {
      dateList.push(
        moment().subtract(i, "days").format("yyyy.MM.DD").toString()
      );
    }

    this.setState({
      updates: updates,
      loading: false,
      date: dateList,
      currentDate: dateList[0],
    });
  }

  async getBlogData(start, end, lang) {
    var option;

    if (lang === "ko") {
      option = "ko/date?start=" + start + "&end=" + end;
    } else {
      option = "en/date?start=" + start + "&end=" + end;
    }

    const updates = await apiUtils.kaciBackendRequest("/techblogs/" + option);

    return updates;
  }

  handlePageClick({ selected: selectedPage }) {
    // console.log(selectedPage);
    this.setState({ currentPage: selectedPage });
  }

  async setStartDate(date) {
    this.setState({
      loading: true,
      currentPage: 0,
    });

    const start = moment(date).format("yyyy-MM-DD 00:00:00").toString();
    const end = moment(this.state.endDate)
      .format("yyyy-MM-DD 23:59:59")
      .toString();

    const updates = await this.getBlogData(start, end, this.state.lang);

    this.setState({
      startDate: date,
      updates: updates,
      loading: false,
    });
  }

  async setEndDate(date) {
    this.setState({
      loading: true,
      currentPage: 0,
    });
    const start = moment(this.state.startDate)
      .format("yyyy-MM-DD 00:00:00")
      .toString();
    const end = moment(date).format("yyyy-MM-DD 23:59:59").toString();

    const updates = await this.getBlogData(start, end, this.state.lang);

    this.setState({
      endDate: date,
      updates: updates,
      loading: false,
    });
  }

  async handleLang(event, lang) {
    this.setState({
      loading: true,
      currentPage: 0,
    });
    const start = moment(this.state.startDate)
      .format("yyyy-MM-DD 00:00:00")
      .toString();
    const end = moment(this.state.endDate)
      .format("yyyy-MM-DD 23:59:59")
      .toString();

    const updates = await this.getBlogData(start, end, lang);

    this.setState({
      lang: lang,
      updates: updates,
      loading: false,
    });
  }

  setPDFloading(loading) {
    //console.log("Loading");
    this.setState({ PDFloading: loading });
  }

  render() {
    const { updates, currentPage, date } = this.state;

    const offset = currentPage * PER_PAGE;
    const currentPageData = updates
      .slice(offset, offset + PER_PAGE)
      .map((item, index) => (
        <Grid item xs={12}>
          <CSPBlogCard data={item}></CSPBlogCard>
        </Grid>
      ));
    const pageCount = Math.ceil(updates.length / PER_PAGE);

    const ExampleCustomInput = ({ value, onClick }) => (
      // <Button
      //   onClick={onClick}
      //   variant="contained"
      //   color="primary"
      //   size="small"
      //   style={{
      //     backgroundColor: "black",
      //     float: "right",
      //     marginLeft: 10,
      //   }}
      // >
      //   {" "}
      //   {value}
      // </Button>
      <button className="button--calendar" onClick={onClick}>
        <svg
          className="icon--calendar-empty"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        {value}
      </button>
    );

    if (this.state.PDFloading) {
      return (
        <>
          <CircularProgress />
          <Typography variant="caption" component="div" color="textSecondary">
            PDF 다운로드 중
          </Typography>
        </>
      );
    } else if (this.state.loading) {
      return <CircularProgress />;
    } else if (updates.length >= 0) {
      return (
        <>
          <Grid className="grid__item--vertical" container spacing={3}>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <ToggleButtonGroup
                value={this.state.lang}
                exclusive
                onChange={this.handleLang}
                aria-label="pdf language"
              >
                <ToggleButton value="ko" aria-label="korean">
                  KO
                </ToggleButton>
                <ToggleButton value="en" aria-label="english">
                  EN
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          <div className="btns__wrapper--pdf">
            <div>
              <DatePicker
                selected={this.state.startDate}
                onChange={(date) => this.setStartDate(date)}
                minDate={subMonths(this.state.endDate, 1)}
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                customInput={<ExampleCustomInput />}
              />

              <DatePicker
                selected={this.state.endDate}
                onChange={(date) => this.setEndDate(date)}
                minDate={this.state.startDate}
                maxDate={addMonths(this.state.startDate, 1)}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                customInput={<ExampleCustomInput />}
              />
            </div>
            <PDFDocumentTechBlogs
              service_list_info={this.state.service_list_info}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              lang={this.state.lang}
              setPDFloading={this.setPDFloading}
            />
          </div>

          <Grid className="grid__item--vertical" container spacing={3}>
            {currentPageData}
          </Grid>
          <ReactPaginate
            previousLabel={"이전"}
            nextLabel={"다음"}
            pageCount={pageCount}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </>
      );
    } else if (!this.state.loading & (updates.length == 0)) {
      return (
        <div>
          <h3>표시할 {this.props.cateogry} 내역이 없습니다.</h3>
        </div>
      );
    } else {
      return <CircularProgress />;
    }
  }
}

export default Articles;
