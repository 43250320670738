import React, { Component } from "react";
import moment from "moment";
import { Document, Page, Text, View, Link, Image } from "@react-pdf/renderer";
import Button from "@material-ui/core/Button";
import { styles } from "../../style/PDFstyle";
import * as apiUtils from "../../utils/apiUtils";
import * as commonFunctions from "../../utils/commonFunctions";
import techimg from "../../static/images/tech.png";
import techPageImgEn from "../../static/images/Tech_firstPage_en.png";
import techPageImgKo from "../../static/images/Tech_firstPage_ko.png";
import techTitlePageImg from "../../static/images/Tech_titlePage.png";
import techContentsPageImgKo from "../../static/images/Tech_contentsPage_ko.png";
import techContentsPageImgEn from "../../static/images/Tech_contentsPage_en.png";
import techArticlePageImg from "../../static/images/Tech_page.png";
import techLine from "../../static/images/Tech_titleLine.png";


class PDFDocument extends Component {
  constructor(props) {
    super(props);
    this.state = { details: [], button: "" };
    this.pdfClick = this.pdfClick.bind(this);
    this.makeContentPdf = this.makeContentPdf.bind(this);
    //this.makeDockerContentPdf=this.makeDockerContentPdf.bind(this);
    //this.makeCNCFContentPdf()
  }

  async componentDidMount() {
    //await this.makeCNCFContentPdf();
  }

  async makeContentPdf(category) {
    const start = moment(this.props.startDate)
      .format("yyyy-MM-DD 00:00:00")
      .toString();
    const end = moment(this.props.endDate)
      .format("yyyy-MM-DD 23:59:59")
      .toString();

    var option;

    if (this.props.lang === "ko") {
      option = category + "/ko/date?start=" + start + "&end=" + end;
    } else {
      option = category + "/en/date?start=" + start + "&end=" + end;
    }

    const details = await apiUtils.kaciBackendRequest("/techblogs/" + option);

    var Contents;

    const articles = [];
    const contents = [];
    let i = 1;

    for (const detail of details) {
      let description = "";

      var date = detail.articleDate;

      description = detail.description;

      var wrapValue = false;
      var breakValue = false;

      if (description.length > 500) 
      {wrapValue = true;
       breakValue = true;
      }

      const imageExtensions = detail.article_image.split(".").pop();

      articles.push(
        <>
          <View style={styles.view} wrap={wrapValue} break={breakValue}>
            <View style={[styles.titleView, {borderColor: commonFunctions.findColor("tech")}]}>
            <Text style={styles.title} id={detail.article_tag + i}>
              {detail.article_title}
            </Text>
            <Text style={styles.title_date}>{date}</Text>
            </View>
            <Image src={techLine}></Image>

            <View style= {styles.articleView}>
            <Text>{description}</Text>
            <Text style={styles.margin}>
              <Link style={styles.link} src={detail.article_href}>
                {" "}
                {this.props.lang === "ko"
                  ? "[상세내용 보러가기]"
                  : "[More Detail]"}
              </Link>
            </Text>
            {/* {(imageExtensions !== 'gif' && imageExtensions !== '') ? <Image src={"https://cors-anywhere.opsinno.cloud/" + detail.article_image}></Image> : <Image src={techimg}></Image>} */}
            {imageExtensions !== "gif" && imageExtensions !== "" ? (
              <Image
                src={
                  "https://opsinno-cors-proxy.herokuapp.com/" +
                  detail.article_image
                }
              ></Image>
            ) : (
              <Image src={techimg}></Image>
            )}
            </View>
          </View>
        </>
      );

      i++;
    }

    let detailsLength = details.length;

    for (let i = 1; i <= detailsLength; i++) {
      var date = details[i - 1].articleDate;
      var number=i;
      if (i < 10) {
        number = "0" + i;
      }
        contents.push(
          <View style={styles.contentView} wrap={false}>
            <Text style={[styles.contentNumber, {color: commonFunctions.findColor("tech")}]}>{number}</Text>
            <View>
            <Text style={styles.contentTitle}>
              {details[i - 1].article_title}
            </Text>
            <Text style={styles.contentMargin}>{date}</Text>
            </View>
          </View>
        );
    }

    Contents = () => (
      <>
       <Page size="A4" orientation="portrait">       
          <Image src={techTitlePageImg} style={styles.pageBackground} />
          <Text
            style={styles.page_title}
          >
            {category}{"\n"}
            {this.props.lang === "ko" ? "블로그\n업데이트" : "Blog\nUpdate"}
          </Text>
        </Page>
        <Page size="A4" orientation="portrait">
         {this.props.lang === "en" && (
              <Image src={techContentsPageImgEn} style={styles.pageBackground} fixed/>
          )}
          {this.props.lang === "ko" && (
              <Image src={techContentsPageImgKo} style={styles.pageBackground} fixed/>
          )}
          <View style={{height: 150}} fixed></View>
          <View style={styles.content}>
          {contents}
          </View>
        </Page>
        {/* <Page size="A4" style={styles.page}>
          <View style={styles.section}>{articles}</View>
        </Page> */}
        <Page size="A4" orientation="portrait">
          <Image src={techArticlePageImg} style={styles.pageBackground} fixed/>
          <View style={styles.page}>{articles}</View>
        </Page>
      </>
    );
    return Contents;
  }

  async pdfClick() {
    this.setState({ button: "true" });
    this.props.setPDFloading(true);
    var CSPUpdateTitle = "";

    if (this.props.lang === "ko") {
      CSPUpdateTitle = "주간 기술 블로그";
    } else {
      CSPUpdateTitle = "Weekly Tech Blog";
    }

    var CNCFContents = await this.makeContentPdf("CNCF");
    var DockerContents = await this.makeContentPdf("Docker");
    //await this.makeDockerContentPdf();

    const before = moment(this.props.startDate).format("yyyy.MM.DD").toString();
    const after = moment(this.props.endDate).format("yyyy.MM.DD").toString();

    const MyDocument = () => (
      <Document keywords="CNCF Docker">
        <Page size="A4" orientation="portrait">
          {this.props.lang === "en" && (
              <Image src={techPageImgEn} style={styles.pageBackground} />
          )}
          {this.props.lang === "ko" && (
              <Image src={techPageImgKo} style={styles.pageBackground} />
          )}
        <Text style={styles.date}>
            (  {before}  ~  {after}  )
          </Text>
        </Page>

        <CNCFContents />
        <DockerContents />
      </Document>
    );

    const beforeDate = moment(this.props.startDate)
      .format("yyyyMMDD")
      .toString();
    const afterDate = moment(this.props.endDate).format("yyyyMMDD").toString();

    if (this.props.lang === "ko")
      await commonFunctions.savePdf(
        <MyDocument />,
        "weekly-tech-blog-update-" +
          beforeDate +
          "-" +
          afterDate +
          "-ko" +
          ".pdf"
      );
    else
      await commonFunctions.savePdf(
        <MyDocument />,
        "weekly-tech-blog-update-" + beforeDate + "-" + afterDate + ".pdf"
      );

    this.setState({ button: "" });
    this.props.setPDFloading(false);
  }

  render() {
    return (
      <>
        <button
          className="button--pdf"
          disabled={this.state.button}
          onClick={() => this.pdfClick()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon--cloud"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
            />
          </svg>
          PDF
        </button>
      </>
    );
  }
}

export default PDFDocument;
