import React, { Component } from "react";
import * as apiUtils from "../../utils/apiUtils";
import * as commonFunctions from "../../utils/commonFunctions";
import moment from "moment";
import { styles } from "../../style/PDFstyle";
import { Document, Page, Text, View, Link, Image } from "@react-pdf/renderer";

import PDFDocumentCSPBlogs from "./PDFDocumentCSPBlogs";
import PDFDocumentCSPNotify from "./PDFDocumentCSPNotify";
import PDFDocumentCSPYoutube from "./PDFDocumentCSPYoutube";

class PDFDocument extends Component {
  constructor(props) {
    super(props);
    this.state = { details: [], button: "" };
    this.pdfClick = this.pdfClick.bind(this);
  }

  async componentDidMount() {}

  async pdfClick() {
    this.setState({ button: "true" });
    this.props.setPDFloading(true);

    var CSPUpdateTitle = "";

    if (this.props.lang === "ko") {
      CSPUpdateTitle = "주간 업데이트";
    } else {
      CSPUpdateTitle = "Weekly Update";
    }

    const details = this.props.details;

    const articles = [];
    const contents = [];
    let i = 1;

    for (const detail of details) {
      const body = [];
      const services = [];
      var articleTag = detail.article_tag
        ? detail.article_tag
        : detail.articleTag;

      var date = detail.articleDate;
      if (this.props.category == "GCP") {
        //GCP의 경우 날짜 데이터에서 시간 제외
        date = detail.articleDate.slice(0, 10);
      }

      for (const b of detail.article_detail) {
        body.push(b + "\n\n");
      }

      for (const s of detail.serviceCategory) {
        for (const service of this.props.service_list_info) {
          var serviceName = Object.keys(service)[0]; //get service name from Taogi
          if (s.includes(serviceName)) {
            //check if the same serviceName
            services.push(service[serviceName].description);
            break;
          }
        }
      }

      var wrapValue = false;
      var breakValue = false;

      if (body.length > 25) {
        wrapValue = true;
        breakValue = true;
      }

      if (services.length > 0) {
        articles.push(
          <>
            <View style={styles.view} wrap={wrapValue} break={breakValue}>
            <View style={[styles.titleView, {borderColor: commonFunctions.findColor(this.props.category)}]}>
            <Text style={styles.title} id={detail.article_tag + i}>
              {detail.article_title}
            </Text>
            <Text style={styles.title_date}>{date}</Text>
            </View>
            <Image src={commonFunctions.findCSPImg("line",this.props.category,this.props.lang)}></Image>

            <View style= {styles.articleView}>
            <Text style={styles.margin}>
                <Link style={styles.link} src={detail.articleHref}>
                  {detail.articleHref}
                </Link>
              </Text>
              <Text>{body}</Text>
              <Text style={[styles.box, {borderColor: commonFunctions.findColor(this.props.category)}]}>{services}</Text>
            </View>
            </View>
          </>
        );
      } else {
        articles.push(
          <>
            <View style={styles.view} wrap={wrapValue} break={breakValue}>
            <View style={[styles.titleView, {borderColor: commonFunctions.findColor(this.props.category)}]}>
            <Text style={styles.title} id={detail.article_tag + i}>
              {detail.article_title}
            </Text>
            <Text style={styles.title_date}>{date}</Text>
            </View>
            <Image src={commonFunctions.findCSPImg("line",this.props.category,this.props.lang)}></Image>

            <View style= {styles.articleView}>
            <Text style={styles.margin}>
                <Link style={styles.link} src={detail.articleHref}>
                  {detail.articleHref}
                </Link>
              </Text>
              <Text>{body}</Text>
            </View>
            </View>
          </>
        );
      }

      i++;
    }

    let detailsLength = details.length;

    for (let i = 1; i <= detailsLength; i++) {
      var date = details[i - 1].articleDate;
      if (this.props.category == "GCP") {
        //GCP의 경우 날짜 데이터에서 시간 제외
        date = details[i - 1].articleDate.slice(0, 10);
      }
      var articleTag = details[i - 1].article_tag
        ? details[i - 1].article_tag
        : details[i - 1].articleTag;

        var number=i;
        if (i < 10) {
          number = "0" + i;
        }
          contents.push(
            <View style={styles.contentView} wrap={false}>
              <Text style={[styles.contentNumber, {color: commonFunctions.findColor(this.props.category)}]}>{number}</Text>
              <View>
              <Text style={styles.contentTitle}>
                {details[i - 1].article_title}
              </Text>
              <Text style={styles.contentMargin}>{date}</Text>
              </View>
            </View>
          );
    }

    const before = moment(this.props.startDate).format("yyyy.MM.DD").toString();
    const after = moment(this.props.endDate).format("yyyy.MM.DD").toString();

    const start = moment(this.props.startDate)
      .format("yyyy-MM-DD 00:00:00")
      .toString();
    const end = moment(this.props.endDate)
      .format("yyyy-MM-DD 23:59:59")
      .toString();

    var option;
    var tech_blog_option;
    var security_blog_option;
    var architecture_blog_option;
    var csp_notify_option;
    var csp_linux_security_option;
    var csp_linux_2_security_option;
    var csp_youtube_option;
    var aws_tech_youtube_option;
    var gcp_tech_youtube_option;

    if (this.props.lang === "ko") {
      option =
        "ko/date?CSP=" +
        this.props.category +
        "&start=" +
        start +
        "&end=" +
        end;
      tech_blog_option =
        "ko/date?CSP=" +
        this.props.category +
        " Tech" +
        "&start=" +
        start +
        "&end=" +
        end;
      security_blog_option =
        "ko/date?CSP=" +
        this.props.category +
        " Security" +
        "&start=" +
        start +
        "&end=" +
        end;
      architecture_blog_option =
        "ko/date?CSP=" +
        this.props.category +
        " Architecture" +
        "&start=" +
        start +
        "&end=" +
        end;
      csp_notify_option =
        "ko/date?CSP=" +
        this.props.category +
        " Security Notify" +
        "&start=" +
        start +
        "&end=" +
        end;
      csp_linux_security_option =
        "ko/date?CSP=" +
        this.props.category +
        " Linux Security Notify" +
        "&start=" +
        start +
        "&end=" +
        end;
      csp_linux_2_security_option =
        "ko/date?CSP=" +
        this.props.category +
        " Linux 2 Security Notify" +
        "&start=" +
        start +
        "&end=" +
        end;
      if (this.props.category === "AWS")
        csp_youtube_option =
          "date?CSP=" +
          this.props.category +
          " Korea Youtube&start=" +
          start +
          "&end=" +
          end;
      else
        csp_youtube_option =
          "date?CSP=" +
          this.props.category +
          " Youtube&start=" +
          start +
          "&end=" +
          end;
    } else {
      option =
        "en/date?CSP=" +
        this.props.category +
        "&start=" +
        start +
        "&end=" +
        end;
      security_blog_option =
        "en/date?CSP=" +
        this.props.category +
        " Security" +
        "&start=" +
        start +
        "&end=" +
        end;
      architecture_blog_option =
        "en/date?CSP=" +
        this.props.category +
        " Architecture" +
        "&start=" +
        start +
        "&end=" +
        end;
      csp_notify_option =
        "en/date?CSP=" +
        this.props.category +
        " Security Notify" +
        "&start=" +
        start +
        "&end=" +
        end;
      csp_linux_security_option =
        "en/date?CSP=" +
        this.props.category +
        " Linux Security Notify" +
        "&start=" +
        start +
        "&end=" +
        end;
      csp_linux_2_security_option =
        "en/date?CSP=" +
        this.props.category +
        " Linux 2 Security Notify" +
        "&start=" +
        start +
        "&end=" +
        end;
      csp_youtube_option =
        "date?CSP=" +
        this.props.category +
        " Youtube&start=" +
        start +
        "&end=" +
        end;
      aws_tech_youtube_option =
        "date?CSP=" +
        this.props.category +
        " Youtube Online Tech Talks&start=" +
        start +
        "&end=" +
        end;
      gcp_tech_youtube_option =
        "date?CSP=" +
        this.props.category +
        " Tech Youtube&start=" +
        start +
        "&end=" +
        end;
    }

    let blog_updates = await apiUtils.kaciBackendRequest("/cspblogs/" + option);
    let tech_blog_updates = await apiUtils.kaciBackendRequest(
      "/cspblogs/" + tech_blog_option
    );
    let aws_security_blog_updates = await apiUtils.kaciBackendRequest(
      "/cspblogs/" + security_blog_option
    );
    let aws_architecture_blog_updates = await apiUtils.kaciBackendRequest(
      "/cspblogs/" + architecture_blog_option
    );
    let aws_security_notify = await apiUtils.kaciBackendRequest(
      "/cspnotify/" + csp_notify_option
    );
    let aws_linux_security_notify = await apiUtils.kaciBackendRequest(
      "/cspnotify/" + csp_linux_security_option
    );
    let aws_linux_2_security_notify = await apiUtils.kaciBackendRequest(
      "/cspnotify/" + csp_linux_2_security_option
    );
    let csp_youtubes = await apiUtils.kaciBackendRequest(
      "/cspyoutube/" + csp_youtube_option
    );
    let aws_tech_youtubes = [];
    let gcp_tech_youtubes = [];
    if (this.props.lang === "en") {
      aws_tech_youtubes = await apiUtils.kaciBackendRequest(
        "/cspyoutube/" + aws_tech_youtube_option
      );
      gcp_tech_youtubes = await apiUtils.kaciBackendRequest(
        "/cspyoutube/" + gcp_tech_youtube_option
      );
    }

    const MyDocument = () => (
      <Document>
        <Page size="A4" orientation="portrait">
          <Image src={commonFunctions.findCSPImg("cover",this.props.category,this.props.lang)} style={styles.pageBackground} />

          <Text style={styles.date}>
            (  {before}  ~  {after}  )
          </Text>
        </Page>

        {aws_security_notify.length > 0 && (
          <>
          <Page size="A4" orientation="portrait">       
           <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
            <Text
              style={styles.page_title}
            >
                {this.props.category}{"\n"}
                {this.props.lang === "ko" ? "보안공지" : "Security\nNotify"}
              </Text>
            </Page>
            <PDFDocumentCSPNotify
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={aws_security_notify}
              lang={this.props.lang}
              isPDF="true"
            />
          </>
        )}
        {aws_linux_security_notify.length > 0 && (
          <>
            <Page size="A4" orientation="portrait">       
              <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
              <Text
                style={styles.page_title}
              >
                {this.props.category}{"\n"}
                {this.props.lang === "ko"
                  ? "리눅스\n보안공지"
                  : "Linux\nSecurity\nNotify"}
              </Text>
            </Page>
            <PDFDocumentCSPNotify
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={aws_linux_security_notify}
              lang={this.props.lang}
              isPDF="true"
            />
          </>
        )}
        {aws_linux_2_security_notify.length > 0 && (
          <>
            <Page size="A4" orientation="portrait">       
            <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
              <Text
                style={styles.page_title}
              >
                {this.props.category}{"\n"}
                {this.props.lang === "ko"
                  ? "리눅스 2\n보안공지"
                  : "Linux 2\nSecurity\nNotify"}
              </Text>
            </Page>
            <PDFDocumentCSPNotify
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={aws_linux_2_security_notify}
              lang={this.props.lang}
              isPDF="true"
            />
          </>
        )}
        {this.props.details.length > 0 && (
          <>
            <Page size="A4" orientation="portrait">       
            <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
              <Text
                style={styles.page_title}
              >
                {this.props.category}{"\n"}
                {this.props.lang === "ko"
                  ? "서비스\n업데이트"
                  : "Service\nUpdate"}
              </Text>
            </Page>
            <Page size="A4" orientation="portrait">
              <Image src={commonFunctions.findCSPImg("contents",this.props.category,this.props.lang)} style={styles.pageBackground} fixed/>
              <View style={{height: 150}} fixed></View>
              <View  style={styles.content}>
            {contents}
          </View>
          </Page>
          <Page size="A4" orientation="portrait">
            <Image src={commonFunctions.findCSPImg("article",this.props.category,this.props.lang)} style={styles.pageBackground} fixed/>
            <View style={styles.page}>{articles}</View>
          </Page>
          </>
        )}
        {blog_updates.length > 0 && (
          <>
            <Page size="A4" orientation="portrait">       
            <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
              <Text
                style={styles.page_title}
              >
                {this.props.category}{"\n"}
                {this.props.lang === "ko" ? "블로그\n업데이트" : "Blog\nUpdate"}
              </Text>
            </Page>
            <PDFDocumentCSPBlogs
              service_list_info={this.state.service_list_info}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={blog_updates}
              lang={this.props.lang}
              isPDF="true"
            />
          </>
        )}
        {tech_blog_updates.length > 0 && (
          <>
            <Page size="A4" orientation="portrait">       
            <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
              <Text
                style={styles.page_title}
              >
                {this.props.category}{"\n"}
                {this.props.lang === "ko"
                  ? "기술블로그\n업데이트"
                  : "Tech Blog\nUpdate"}
              </Text>
            </Page>
            <PDFDocumentCSPBlogs
              service_list_info={this.state.service_list_info}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={tech_blog_updates}
              lang={this.props.lang}
              isPDF="true"
            />
          </>
        )}
        {aws_security_blog_updates.length > 0 && (
          <>
          <Page size="A4" orientation="portrait">       
            <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
              <Text
                style={styles.page_title}
              >
                {this.props.category}{"\n"}
                {this.props.lang === "ko"
                  ? "보안블로그\n업데이트"
                  : "Security\nBlog\nUpdate"}
              </Text>
            </Page>
            <PDFDocumentCSPBlogs
              service_list_info={this.state.service_list_info}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={aws_security_blog_updates}
              lang={this.props.lang}
              isPDF="true"
            />
          </>
        )}
        {aws_architecture_blog_updates.length > 0 && (
          <>
          <Page size="A4" orientation="portrait">       
            <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
              <Text
                style={styles.page_title}
              >
                {this.props.category}{"\n"}
                {this.props.lang === "ko"
                  ? "아키텍처\n블로그\n업데이트"
                  : "Architecture\nBlog\nUpdate"}
              </Text>
            </Page>
            <PDFDocumentCSPBlogs
              service_list_info={this.state.service_list_info}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={aws_architecture_blog_updates}
              lang={this.props.lang}
              isPDF="true"
            />
          </>
        )}
        {csp_youtubes.length > 0 && (
          <>
            <Page size="A4" orientation="portrait">       
            <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
              <Text
                style={styles.page_title}
              >
                {this.props.category}{"\n"}
                {this.props.lang === "ko" ? " 유튜브" : " Youtube"}
              </Text>
            </Page>
            <PDFDocumentCSPYoutube
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={csp_youtubes}
              lang={this.props.lang}
              isPDF="true"
            />
          </>
        )}
        {aws_tech_youtubes.length > 0 && (
          <>
            <Page size="A4" orientation="portrait">       
            <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
              <Text
                style={styles.page_title}
              >
                {this.props.category}{"\n"}
                {this.props.lang === "ko" ? " 기술 유튜브" : " Tech\nYoutube"}
              </Text>
            </Page>
            <PDFDocumentCSPYoutube
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={aws_tech_youtubes}
              lang={this.props.lang}
              isPDF="true"
            />
          </>
        )}
        {gcp_tech_youtubes.length > 0 && (
          <>
            <Page size="A4" orientation="portrait">       
            <Image src={commonFunctions.findCSPImg("title",this.props.category,this.props.lang)} style={styles.pageBackground} />
              <Text
                style={styles.page_title}
              >
                {this.props.category}{"\n"}
                {this.props.lang === "ko" ? " 기술 유튜브" : " Tech\nYoutube"}
              </Text>
            </Page>
            <PDFDocumentCSPYoutube
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={gcp_tech_youtubes}
              lang={this.props.lang}
              isPDF="true"
            />
          </>
        )}
      </Document>
    );

    const beforeDate = moment(this.props.startDate)
      .format("yyyyMMDD")
      .toString();
    const afterDate = moment(this.props.endDate).format("yyyyMMDD").toString();

    if (this.props.lang === "ko")
      await commonFunctions.savePdf(
        <MyDocument />,
        this.props.category.toLowerCase() +
          "-weekly-update-" +
          beforeDate +
          "-" +
          afterDate +
          "-ko" +
          ".pdf"
      );
    else
      await commonFunctions.savePdf(
        <MyDocument />,
        this.props.category.toLowerCase() +
          "-weekly-update-" +
          beforeDate +
          "-" +
          afterDate +
          ".pdf"
      );

    this.setState({ button: "" });
    this.props.setPDFloading(false);
  }

  render() {
    return (
      <>
        <button
          className="button--pdf"
          disabled={this.state.button}
          onClick={() => this.pdfClick()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon--cloud"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
            />
          </svg>
          PDF
        </button>
        {/* <Button
          disabled={this.state.button}
          onClick={() => this.pdfClick()}
          variant="contained"
          color="primary"
          size="small"
          style={{ backgroundColor: "#A50034", float: "right" }}
        >
          PDF
        </Button> */}
      </>
    );
  }
}

export default PDFDocument;
