import React, { Component } from "react";
import * as apiUtils from "../utils/apiUtils";
import Grid from "@material-ui/core/Grid";
import ReactPaginate from "react-paginate";
import CSPUpdateCard from "../components/Card/CSPUpdateCard";
import moment from "moment";
import { CircularProgress, rgbToHex } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PDFDocument from "../components/PDF/PDFDocument";
import Typography from "@material-ui/core/Typography";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import subMonths from "date-fns/subMonths";
import addMonths from "date-fns/addMonths";
import subDays from "date-fns/subDays";
import { ko } from "date-fns/esm/locale";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const PER_PAGE = 4;

class Articles extends Component {
  constructor(props) {
    super(props);
    if (this.props.category == "NCP") {
      this.state = {
        updates: [],
        filteredUpdates: [],
        currentPage: 0,
        date: [],
        loading: true,
        currentDate: "",
        button: "",
        startDate: subMonths(new Date(), 1),
        endDate: new Date(),
        serviceCategory: [],
        service_list_info: [],
        lang: "ko",
        PDFloading: false,
      };
    } else {
      this.state = {
        updates: [],
        filteredUpdates: [],
        currentPage: 0,
        date: [],
        loading: true,
        currentDate: "",
        button: "",
        startDate: subDays(new Date(), 7),
        endDate: new Date(),
        serviceCategory: [],
        service_list_info: [],
        lang: "ko",
        PDFloading: false,
      };
    }

    this.handlePageClick = this.handlePageClick.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.handleServiceCategory = this.handleServiceCategory.bind(this);
    this.isServiceExists = this.isServiceExists.bind(this);
    this.filterUpdates = this.filterUpdates.bind(this);
    this.handleLang = this.handleLang.bind(this);
    this.setPDFloading = this.setPDFloading.bind(this);
    this.getUpdateData = this.getUpdateData.bind(this);
  }

  async componentDidMount() {
    let dateList = [];
    for (var i = 0; i < 5; i++) {
      dateList.push(
        moment().subtract(i, "days").format("yyyy.MM.DD").toString()
      );
    }

    const start = moment(this.state.startDate)
      .format("yyyy-MM-DD 00:00:00")
      .toString();
    const end = moment(this.state.endDate)
      .format("yyyy-MM-DD 23:59:59")
      .toString();

    const updates = await this.getUpdateData(start, end, this.state.lang);

    let service_list_info = [];
    if (this.props.category != "NCP")
      service_list_info = await apiUtils.TaogiRequest(
        "/" + this.props.category.toLowerCase()
      );

    this.setState({
      updates: updates,
      filteredUpdates: updates,
      loading: false,
      date: dateList,
      currentDate: dateList[0],
      service_list_info: service_list_info,
      serviceCategory: [],
    });
  }

  async getUpdateData(start, end, lang) {
    var option;
    if (lang === "ko") {
      option =
        "detail/ko/date?tag=" +
        this.props.category +
        "_UPDATE" +
        "&start=" +
        start +
        "&end=" +
        end;
    } else {
      option =
        "detail/date?tag=" +
        this.props.category +
        "_UPDATE" +
        "&start=" +
        start +
        "&end=" +
        end;
    }

    const updates = await apiUtils.kaciBackendRequest("/cspupdate/" + option);

    return updates;
  }

  handlePageClick({ selected: selectedPage }) {
    // console.log(selectedPage);
    this.setState({ currentPage: selectedPage });
  }

  async setStartDate(date) {
    this.setState({
      loading: true,
      currentPage: 0,
    });

    const start = moment(date).format("yyyy-MM-DD 00:00:00").toString();
    const end = moment(this.state.endDate)
      .format("yyyy-MM-DD 23:59:59")
      .toString();

    const updates = await this.getUpdateData(start, end, this.state.lang);

    const filteredUpdates = this.filterUpdates(updates);
    //console.log(filteredUpdates);

    this.setState({
      startDate: date,
      updates: updates,
      filteredUpdates: filteredUpdates,
      loading: false,
    });
  }

  async setEndDate(date) {
    this.setState({
      loading: true,
      currentPage: 0,
    });
    const start = moment(this.state.startDate)
      .format("yyyy-MM-DD 00:00:00")
      .toString();
    const end = moment(date).format("yyyy-MM-DD 23:59:59").toString();

    const updates = await this.getUpdateData(start, end, this.state.lang);

    const filteredUpdates = this.filterUpdates(updates);
    //console.log(filteredUpdates);

    this.setState({
      endDate: date,
      updates: updates,
      filteredUpdates: filteredUpdates,
      loading: false,
    });
  }

  filterUpdates(updates) {
    const filteredUpdates = [];
    if (this.state.serviceCategory.length > 0) {
      for (const update of updates) {
        if (
          this.isServiceExists(
            this.state.serviceCategory,
            update.serviceCategory
          )
        ) {
          filteredUpdates.push(update);
        }
      }
      return filteredUpdates;
    } else {
      return updates;
    }
  }

  handleServiceCategory(event, newserviceCategory) {
    this.setState({
      loading: true,
      currentPage: 0,
    });
    const filteredUpdates = [];
    if (newserviceCategory.length > 0) {
      for (const update of this.state.updates) {
        if (this.isServiceExists(newserviceCategory, update.serviceCategory)) {
          filteredUpdates.push(update);
        }
      }
      this.setState({
        loading: false,
        serviceCategory: newserviceCategory,
        filteredUpdates: filteredUpdates,
      });
    } else {
      this.setState({
        loading: false,
        serviceCategory: newserviceCategory,
        filteredUpdates: this.state.updates,
      });
    }
  }

  isServiceExists(taogi, updateServiceList) {
    for (const t of taogi) {
      if (updateServiceList.includes(t)) {
        return true;
      }
    }
    return false;
  }

  async handleLang(event, lang) {
    this.setState({
      loading: true,
      currentPage: 0,
    });
    const start = moment(this.state.startDate)
      .format("yyyy-MM-DD 00:00:00")
      .toString();
    const end = moment(this.state.endDate)
      .format("yyyy-MM-DD 23:59:59")
      .toString();

    const updates = await this.getUpdateData(start, end, lang);

    const filteredUpdates = this.filterUpdates(updates);

    this.setState({
      lang: lang,
      updates: updates,
      filteredUpdates: filteredUpdates,
      loading: false,
    });
  }

  setPDFloading(loading) {
    //console.log("Loading");
    this.setState({ PDFloading: loading });
  }

  render() {
    const { filteredUpdates, currentPage, date, service_list_info } =
      this.state;

    const offset = currentPage * PER_PAGE;
    const currentPageData = filteredUpdates
      .slice(offset, offset + PER_PAGE)
      .map((item, index) => (
        <Grid item xs={12}>
          <CSPUpdateCard data={item}></CSPUpdateCard>
        </Grid>
      ));
    const pageCount = Math.ceil(filteredUpdates.length / PER_PAGE);

    const serviceList = [];

    for (const service of service_list_info) {
      serviceList.push(Object.keys(service)[0]);
    }
    serviceList.push("etc");

    const ExampleCustomInput = ({ value, onClick }) => (
      // <Button
      //   onClick={onClick}
      //   variant="contained"
      //   color="primary"
      //   size="small"
      //   style={{
      //     backgroundColor: "black",
      //     float: "right",
      //     marginLeft: 10,
      //   }}
      // >
      //   {" "}
      //   {value}
      // </Button>
      <button className="button--calendar" onClick={onClick}>
        <svg
          className="icon--calendar-empty"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
        {value}
      </button>
    );
    if (this.state.PDFloading) {
      return (
        <>
          <CircularProgress />
          <Typography variant="caption" component="div" color="textSecondary">
            PDF 다운로드 중
          </Typography>
        </>
      );
    } else if (this.state.loading) {
      return <CircularProgress />;
    } else if (filteredUpdates.length >= 0 && this.props.category == "NCP") {
      return (
        <>
          <Grid className="grid__item--vertical" container spacing={3}>
            <Grid item xs={10}>
              <Autocomplete
                multiple
                options={serviceList}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                onChange={this.handleServiceCategory}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Service Category"
                    placeholder="service category"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}></Grid>
          </Grid>
          <div className="btns__wrapper--pdf">
            <div>
              <DatePicker
                selected={this.state.startDate}
                onChange={(date) => this.setStartDate(date)}
                minDate={subMonths(this.state.endDate, 12)}
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                customInput={<ExampleCustomInput />}
              />
              <DatePicker
                selected={this.state.endDate}
                onChange={(date) => this.setEndDate(date)}
                minDate={this.state.startDate}
                maxDate={addMonths(this.state.startDate, 6)}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                customInput={<ExampleCustomInput />}
              />
            </div>
            <PDFDocument
              service_list_info={this.state.service_list_info}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={this.state.filteredUpdates}
              lang={this.state.lang}
              setPDFloading={this.setPDFloading}
            />
          </div>

          <Grid className="grid__item--vertical" container spacing={3}>
            {currentPageData}
          </Grid>
          <ReactPaginate
            previousLabel={"이전"}
            nextLabel={"다음"}
            pageCount={pageCount}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </>
      );
    } else if (filteredUpdates.length >= 0) {
      return (
        <>
          <Grid className="grid__item--vertical" container spacing={3}>
            <Grid item xs={10}>
              <Autocomplete
                multiple
                options={serviceList}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                onChange={this.handleServiceCategory}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Service Category"
                    placeholder="service category"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <ToggleButtonGroup
                value={this.state.lang}
                exclusive
                onChange={this.handleLang}
                aria-label="pdf language"
              >
                <ToggleButton value="ko" aria-label="korean">
                  KO
                </ToggleButton>
                <ToggleButton value="en" aria-label="english">
                  EN
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          <div className="btns__wrapper--pdf">
            <div>
              <DatePicker
                selected={this.state.startDate}
                onChange={(date) => this.setStartDate(date)}
                minDate={subMonths(this.state.endDate, 1)}
                maxDate={new Date()}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                customInput={<ExampleCustomInput />}
              />

              <DatePicker
                selected={this.state.endDate}
                onChange={(date) => this.setEndDate(date)}
                minDate={this.state.startDate}
                maxDate={addMonths(this.state.startDate, 1)}
                dateFormat="yyyy-MM-dd"
                locale={ko}
                customInput={<ExampleCustomInput />}
              />
            </div>
            <PDFDocument
              service_list_info={this.state.service_list_info}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              category={this.props.category}
              details={this.state.filteredUpdates}
              lang={this.state.lang}
              setPDFloading={this.setPDFloading}
            />
          </div>

          <Grid className="grid__item--vertical" container spacing={3}>
            {currentPageData}
          </Grid>
          <ReactPaginate
            previousLabel={"이전"}
            nextLabel={"다음"}
            pageCount={pageCount}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </>
      );
    } else if (!this.state.loading & (filteredUpdates.length == 0)) {
      return (
        <div>
          <h3>표시할 {this.props.category} 내역이 없습니다.</h3>
        </div>
      );
    } else {
      return <CircularProgress />;
    }
  }
}

export default Articles;
