import {
    StyleSheet,
    Font
} from '@react-pdf/renderer';

Font.register('https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf', {
    family: 'NanumGothic-Regular'
});
Font.register('https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Bold.ttf', {
    family: 'NanumGothic-Bold'
});
Font.register({
    family: "jpFont",
    src: "https://github.com/wheatandcat/react-curriculum-vitae/raw/master/src/fonts/Nasu-Regular.ttf",
    unicodeRange: "U+3040—309F"
});

export const styles = StyleSheet.create({
    page: {
        fontFamily: 'NanumGothic-Regular',
        fontSize: 10,
        color: '#262603'
    },
    // pageFirst: {
    //     //backgroundColor: '#E4E4E4',
    //     fontFamily: "NanumGothic-Bold",
    //     justifyContent: "center",
    //     alignItems: "center"
    // },
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
    },
    date: {
        fontFamily: "NanumGothic-Bold",
        position: 'absolute',
        top: '44%' ,
        left: '39%',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: 9,
    },
    page_title : {
        fontSize: 60,
        left: '13%',
        top: '28%',
        fontFamily: 'NanumGothic-Regular',
    },
    section: {
        flexGrow: 1
    },
    view: {
        marginTop: 15,
        // margin: 20,
        marginBottom: 40, //paddingBottom 값과 동일하거나 크게 맞춰야 에러안남
        paddingBottom: 40,
    },
    titleView: {
        borderLeft: 7,
        padding: 1,
        paddingRight: 10,
        paddingLeft: 10,
        marginBottom: 15,
        marginLeft: 20,
        marginRight: 20,
    },
    articleView: {
        marginTop: 10,
        marginLeft: 20,
        marginRight: 20,
    },
    title: {
        fontSize: 14,
        fontFamily: "NanumGothic-Regular",
    },
    link: {
        color: '#636363'
    },
    contentsLink: {
        color: 'black',
        textDecoration: 'none'
    },
    title_date: {
        fontSize: 9,
        marginTop: 1
    },
    margin: {
        marginBottom: 10,
        marginTop: 10
    },
    box: {
        borderTop: 1,
        borderBottom: 1,
        borderLeft: 1,
        borderRight: 1,
        //borderColor: '#bd0039',
        borderStyle: 'dashed',
        padding: 3
    },
    content: {
        fontFamily: 'NanumGothic-Regular',
        fontSize: 10,
        color: '#262603',
        paddingLeft: 50,
        paddingRight: 50
    },
    contentView: {
        padding: 20,
        paddingLeft:30, 
        paddingRight: 30,
        borderTop: 0.5,
        borderColor: '#807b7a',
        borderStyle: 'dashed',
        flexDirection: 'row',
    },
    contentNumber: {
        fontSize: 18,
        paddingRight: 15,
        //color: '#bd0039',
        //color: '#76c5d5',  //tech color
        fontFamily: 'NanumGothic-Bold',
    },
    contentTitle: {
        fontSize: 12,
        marginBottom: 5,
        paddingRight: 30
    },
    contentMargin: {
        color: '#807b7a',
        fontSize: 9,
    },
    logo: {
        height: 170,
        marginTop: 160,
        marginBottom: 190
    },
    teamlogo: {
        height: 45,
        alignItems: "Right",
        paddingLeft: 130

    },
    cnslogo: {
        paddingTop: 20,
        alignItems: "Left",
        paddingRight: 130

    },
    pageNation: {
        fontSize: 10,
        alignItems: 'center'
    }
});