import React, { Component } from "react";
import * as apiUtils from "../utils/apiUtils";
import Grid from "@material-ui/core/Grid";
import ReactPaginate from "react-paginate";
import CSPIncidentCard from "../components/Card/CSPIncidentCard";
import CSPIncidentGCPCard from "../components/Card/CSPIncidentGCPCard";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

const URL = "https://status.cloud.google.com/incidents.json";
const PER_PAGE = 4;

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updates: [],
      currentPage: 0,
      date: [],
      loading: true,
      currentDate: "",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  async componentDidMount() {
    let dateList = [];
    for (var i = 0; i < 5; i++) {
      dateList.push(
        moment().subtract(i, "days").format("yyyy.MM.DD").toString()
      );
    }
    let updates = [];

    if (this.props.category == "GCP") {
      updates = await apiUtils.openApiRequest(URL);
    } else {
      updates = await apiUtils.kaciBackendRequest(
        "/cspincidents/" + this.props.category
      );
    }

    this.setState({
      updates: updates,
      loading: false,
      date: dateList,
      currentDate: dateList[0],
    });
  }

  handlePageClick({ selected: selectedPage }) {
    // console.log(selectedPage);
    this.setState({ currentPage: selectedPage });
  }

  render() {
    const { updates, currentPage, date } = this.state;

    const offset = currentPage * PER_PAGE;
    let currentPageData;

    if (this.props.category == "GCP") {
      currentPageData = updates
        .slice(offset, offset + PER_PAGE)
        .map((item, index) => (
          <Grid item xs={12}>
            <CSPIncidentGCPCard data={item}></CSPIncidentGCPCard>
          </Grid>
        ));
    } else {
      currentPageData = updates
        .slice(offset, offset + PER_PAGE)
        .map((item, index) => (
          <Grid item xs={12}>
            <CSPIncidentCard data={item}></CSPIncidentCard>
          </Grid>
        ));
    }

    const pageCount = Math.ceil(updates.length / PER_PAGE);

    if (updates.length > 0) {
      return (
        <>
          <Grid className="grid__item--vertical" container spacing={3}>
            {currentPageData}
          </Grid>
          <ReactPaginate
            previousLabel={"이전"}
            nextLabel={"다음"}
            pageCount={pageCount}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
        </>
      );
    } else if (!this.state.loading & (updates.length == 0)) {
      return (
        <div>
          <h3>표시할 {this.props.tag} 내역이 없습니다.</h3>
        </div>
      );
    } else {
      return <CircularProgress />;
    }
  }
}

export default Articles;
