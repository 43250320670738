import React from "react";
import TabPanel from "./Tab/TabPanel";
import NavBar from "../components/NavBar";
import "../style/App.css";
import { Route, Redirect, Switch } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <NavBar />
      <div>
        {/*<TabPanel /> */}
        <Switch>
          <Redirect exact from="/" to="/신기술 뉴스/클라우드" />
          <Route path="/:id1/:id2" component={TabPanel} />
        </Switch>
      </div>
    </div>
  );
}

export default App;
