import {
  ContactsOutlined
} from '@material-ui/icons';
import {
  pdf
} from '@react-pdf/renderer';
import awsCoverPageImgEn from "../static/images/AWS_firstPage_en.png";
import awsCoverPageImgKo from "../static/images/AWS_firstPage_ko.png";
import gcpCoverPageImgEn from "../static/images/GCP_firstPage_en.png";
import gcpCoverPageImgKo from "../static/images/GCP_firstPage_ko.png";
import azureCoverPageImgEn from "../static/images/Azure_firstPage_en.png";
import azureCoverPageImgKo from "../static/images/Azure_firstPage_ko.png";
import ncpCoverPageImgEn from "../static/images/NCP_firstPage_en.png";
import ncpCoverPageImgKo from "../static/images/NCP_firstPage_ko.png";
import awsTitlePageImg from "../static/images/AWS_titlePage.png";
import gcpTitlePageImg from "../static/images/GCP_titlePage.png";
import azureTitlePageImg from "../static/images/Azure_titlePage.png";
import ncpTitlePageImg from "../static/images/NCP_titlePage.png";
import awsContentsPageImgKo from "../static/images/AWS_contentsPage_ko.png";
import awsContentsPageImgEn from "../static/images/AWS_contentsPage_en.png";
import gcpContentsPageImgKo from "../static/images/GCP_contentsPage_ko.png";
import gcpContentsPageImgEn from "../static/images/GCP_contentsPage_en.png";
import azureContentsPageImgKo from "../static/images/Azure_contentsPage_ko.png";
import azureContentsPageImgEn from "../static/images/Azure_contentsPage_en.png";
import ncpContentsPageImgKo from "../static/images/NCP_contentsPage_ko.png";
import ncpContentsPageImgEn from "../static/images/NCP_contentsPage_en.png";
import awsArticlePage from "../static/images/AWS_page.png";
import gcpArticlePage from "../static/images/GCP_page.png";
import azureArticlePage from "../static/images/Azure_page.png";
import ncpArticlePage from "../static/images/NCP_page.png";
import awsLine from "../static/images/AWS_titleLine.png";
import gcpLine from "../static/images/GCP_titleLine.png";
import azureLine from "../static/images/Azure_titleLine.png";
import ncpLine from "../static/images/NCP_titleLine.png";


const saveBlob = (blob, filename) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.style.display = "none";
  let url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  window.URL.revokeObjectURL(url);
};

export const savePdf = async (document, filename) => {
  saveBlob(await pdf(document).toBlob(), filename);
};

export function findCSPImg(type, CSP, lan) {
  let img;
  if (type === "cover") {
    if (CSP.includes("AWS") && lan === "en")
      img = awsCoverPageImgEn;
    else if (CSP.includes( "AWS") && lan === "ko")
      img = awsCoverPageImgKo;
    else if (CSP.includes( "GCP") && lan === "en")
      img = gcpCoverPageImgEn;
    else if (CSP.includes( "GCP") && lan === "ko")
      img = gcpCoverPageImgKo;
    else if (CSP.includes( "Azure") && lan === "en")
      img = azureCoverPageImgEn;
    else if (CSP.includes( "Azure") && lan === "ko")
      img = azureCoverPageImgKo;
    else if (CSP.includes( "NCP") && lan === "en")
      img = ncpCoverPageImgEn;
    else if (CSP.includes( "NCP") && lan === "ko")
      img = ncpCoverPageImgKo;
  }
  else if (type === "title") {
    if (CSP.includes( "AWS"))
      img = awsTitlePageImg;
    else if (CSP.includes( "GCP"))
      img = gcpTitlePageImg;
    else if (CSP.includes( "Azure"))
      img = azureTitlePageImg;
    else if (CSP.includes( "NCP"))
      img = ncpTitlePageImg;
  }
  else if (type === "contents") {
    if (CSP.includes( "AWS") && lan === "en")
      img = awsContentsPageImgEn;
    else if (CSP.includes( "AWS") && lan === "ko")
      img = awsContentsPageImgKo;
    else if (CSP.includes( "GCP") && lan === "en")
      img = gcpContentsPageImgEn;
    else if (CSP.includes( "GCP") && lan === "ko")
      img = gcpContentsPageImgKo;
    else if (CSP.includes( "Azure") && lan === "en")
      img = azureContentsPageImgEn;
    else if (CSP.includes( "Azure") && lan === "ko")
      img = azureContentsPageImgKo;
    else if (CSP.includes( "NCP") && lan === "en")
      img = ncpContentsPageImgEn;
    else if (CSP.includes( "NCP") && lan === "ko")
      img = ncpContentsPageImgKo;
  }
  else if(type =="article") {
    if (CSP.includes( "AWS"))
      img = awsArticlePage;
    else if (CSP.includes( "GCP"))
      img = gcpArticlePage;
    else if (CSP.includes( "Azure"))
      img = azureArticlePage;
    else if (CSP.includes( "NCP"))
      img = ncpArticlePage;
  }
  else if(type =="line") {
    if (CSP.includes( "AWS"))
      img = awsLine;
    else if (CSP.includes( "GCP"))
      img = gcpLine;
    else if (CSP.includes( "Azure"))
      img = azureLine;
    else if (CSP.includes( "NCP"))
      img = ncpLine;
  }
  
  return img
}

export function findColor(type) {
  if (type.includes("AWS")) {
    return '#ff9506';
  }
  else if(type.includes("GCP")) {
    return '#4285f4'
  }
  else if(type.includes("Azure")) {
    return '#0089d6'
  }
  else if(type.includes("NCP")) {
    return '#00da69'
  }
  else if (type.includes("tech")) {
    return '#76c5d5'
  }

}