import React, { Component } from "react";
import moment from "moment";
import { Document, Page, Text, View, Link, Image } from "@react-pdf/renderer";
import Button from "@material-ui/core/Button";
import { styles } from "../../style/PDFstyle";
import * as commonFunctions from "../../utils/commonFunctions";

var Contents;

class PDFDocument extends Component {
  constructor(props) {
    super(props);
    this.state = { details: [], button: "" };
    this.pdfClick = this.pdfClick.bind(this);
    this.makeContentPdf = this.makeContentPdf.bind(this);
    this.makeContentPdf();
  }

  async componentDidMount() {
    await this.makeContentPdf();
  }

  async makeContentPdf() {
    const details = this.props.details;

    const articles = [];
    const contents = [];
    let i = 1;

    for (const detail of details) {
      let description = "";

      var date = detail.articleDate;

      for (const line of detail.description) description += line + "\n";

      var wrapValue = false;
      var breakValue = false;

      if (description.length > 1000) {
        wrapValue = true;
        breakValue = true;
      }

      articles.push(
        <>
          <View style={styles.view} wrap={wrapValue} break={breakValue}>
            <View style={[styles.titleView, {borderColor: commonFunctions.findColor(this.props.category)}]}>
            <Text style={styles.title} id={detail.article_tag + i}>
              {detail.article_title}
            </Text>
            <Text style={styles.title_date}>{date}</Text>
            </View>
            <Image src={commonFunctions.findCSPImg("line",this.props.category,this.props.lang)}></Image>

            <View style= {styles.articleView}>
            <Text>{description}</Text>
            <Text style={styles.margin}>
              <Link style={styles.link} src={detail.article_href}>
                {" "}
                {this.props.lang === "ko"
                  ? "[상세내용 보러가기]"
                  : "[More Detail]"}
              </Link>
            </Text>
            </View>
          </View>
        </>
      );

      i++;
    }

    let detailsLength = details.length;

    for (let i = 1; i <= detailsLength; i++) {
      var date = details[i - 1].articleDate;

      var number=i;
      if (i < 10) {
        number = "0" + i;
      }
        contents.push(
          <View style={styles.contentView} wrap={false}>
            <Text style={[styles.contentNumber, {color: commonFunctions.findColor(this.props.category)}]}>{number}</Text>
            <View>
            <Text style={styles.contentTitle}>
              {details[i - 1].article_title}
            </Text>
            <Text style={styles.contentMargin}>{date}</Text>
            </View>
          </View>
        );
    }

    Contents = () => (
      <>
        <Page size="A4" orientation="portrait">
              <Image src={commonFunctions.findCSPImg("contents",this.props.category,this.props.lang)} style={styles.pageBackground} fixed/>
              <View style={{height: 150}} fixed></View>
              <View  style={styles.content}>
            {contents}
          </View>
        </Page>
        <Page size="A4" orientation="portrait">
            <Image src={commonFunctions.findCSPImg("article",this.props.category,this.props.lang)} style={styles.pageBackground} fixed/>
            <View style={styles.page}>{articles}</View>
        </Page>
      </>
    );
  }

  async pdfClick() {
    this.setState({ button: "true" });
    this.props.setPDFloading(true);
    var CSPUpdateTitle = "";

    if (this.props.lang === "ko") {
      CSPUpdateTitle = "";
    } else {
      CSPUpdateTitle = "";
    }

    await this.makeContentPdf();

    const before = moment(this.props.startDate).format("yyyy.MM.DD").toString();
    const after = moment(this.props.endDate).format("yyyy.MM.DD").toString();

    const MyDocument = () => (
      <Document>
        <Page size="A4" orientation="portrait">
          <Image src={commonFunctions.findCSPImg("cover",this.props.category,this.props.lang)} style={styles.pageBackground} />

          <Text style={styles.date}>
            (  {before}  ~  {after}  )
          </Text>
        </Page>
        <Contents />
      </Document>
    );

    const beforeDate = moment(this.props.startDate)
      .format("yyyyMMDD")
      .toString();
    const afterDate = moment(this.props.endDate).format("yyyyMMDD").toString();

    if (this.props.lang === "ko")
      await commonFunctions.savePdf(
        <MyDocument />,
        this.props.category.toLowerCase() +
          "-weekly-notify-update-" +
          beforeDate +
          "-" +
          afterDate +
          "-ko" +
          ".pdf"
      );
    else
      await commonFunctions.savePdf(
        <MyDocument />,
        this.props.category.toLowerCase() +
          "-weekly-notify-update-" +
          beforeDate +
          "-" +
          afterDate +
          ".pdf"
      );

    this.setState({ button: "" });
    this.props.setPDFloading(false);
  }

  render() {
    if (this.props.isPDF == "true") {
      return <Contents />;
    } else {
      return (
        <>
          <button
            className="button--pdf"
            disabled={this.state.button}
            onClick={() => this.pdfClick()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon--cloud"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10"
              />
            </svg>
            PDF
          </button>
        </>
      );
    }
  }
}

export default PDFDocument;
