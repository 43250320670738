import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { CardActionArea } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    minWidth: 350,
    minHeight: 100,
    textAlign: "left",
    fontFamily: "Noto Sans KR",
  },
  title: {
    fontFamily: "Noto Sans KR",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  header: {
    fontSize: 14,
    fontFamily: "Noto Sans KR",
  },
  pos: {
    marginBottom: 12,
    fontFamily: "Noto Sans KR",
  },
});

export default function ArticleCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const dataJson = {
    headline: "AWS, 한국 유망 스타트업 육성 프로그램 가동",
    content:
      "아마존웹서비스(AWS)는 중소벤처기업부, 창업진흥원과 함께 E-커머스, 핀테크, 스마트 물류, AI 활용 분야에서 두각을 나타내는 한국 내 스타트업을 위한 신규 프로그램을 실시한다고 8일 밝혔다.\n\
    ‘정글’이라고 불리는 신규 프로그램은 혁신기술을 보유한 30개의 스타트업을 차기 유니콘 기업(기업 가치 10억 달러 이상)으로 성장할 수 있도록 지원하기 위해 마련됐다.\n",
  };
  let data = props.data ? props.data : dataJson;
  //console.log(data.article_title);
  var serviceCategory = "";
  if (data.serviceCategory) {
    for (const d of data.serviceCategory) {
      serviceCategory += d + " ";
    }
  }
  //console.log(serviceCategory);
  return (
    <Card
      className={`${classes.root} card`}
      onClick={() => {
        window.open(data.articleHref, "_blank");
      }}
    >
      <CardContent>
        <Typography
          className={classes.header}
          color="textSecondary"
          gutterBottom
        >
          {serviceCategory}
        </Typography>
        <Typography variant="h5" component="h4" className={classes.title}>
          {data.article_title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {data.articleDate}
        </Typography>
        <Typography variant="body2" component="p">
          {data.article_body.split("\n").map((line, idx) => (
            <p key={idx}>{line}</p>
          ))}
        </Typography>
      </CardContent>
      <CardActions>
        {/* <Button
          onClick={() => {
            window.open(data.articleHref, "_blank");
          }}
          variant="contained"
          color="primary"
          size="small"
          style={{ backgroundColor: "#A50034", marginLeft: "auto" }}
        >
          Detail
        </Button> */}
      </CardActions>
    </Card>
  );
}
