import React, { Component } from "react";
import * as apiUtils from "../utils/apiUtils";
import Grid from "@material-ui/core/Grid";
import ArticleImageCard from "../components/Card/ArticleImageCard";
import ArticleCardOnlyTitle from "../components/Card/ArticleCardOnlyTitle";
import ReactPaginate from "react-paginate";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import DvrIcon from "@material-ui/icons/Dvr";
import ArtTrackIcon from "@material-ui/icons/ArtTrack";
import SearchBar from "material-ui-search-bar";

class Articles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: [], //data
      articleOrg: [],
      currentPage: 0,
      date: [],
      loading: true,
      currentDate: "",
      currentView: "imageView",
      PER_PAGE: 8,
      searchInput: "",
    };
    this.handlePageClick = this.handlePageClick.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
    this.handleViewClick = this.handleViewClick.bind(this);
  }

  async componentDidMount() {
    let dateList = [];
    for (var i = 0; i < 5; i++) {
      dateList.push(
        moment().subtract(i, "days").format("yyyy.MM.DD").toString()
      );
    }
    let article = await apiUtils.kaciBackendRequest(
      "/article/getArticles?category=" + this.props.category
    );
    this.setState({
      article: article,
      articleOrg: article,
      loading: false,
      date: dateList,
    });
  }

  handlePageClick({ selected: selectedPage }) {
    this.setState({ currentPage: selectedPage });
  }

  handleViewClick(selectedView, per_page) {
    this.setState({
      currentView: selectedView,
      PER_PAGE: per_page,
    });
  }
  async handleDateClick(selectDay) {
    this.setState({
      loading: true,
      currentPage: 0,
    });

    let dateList = [];

    let dayDiff = moment(moment().format("yyyy.MM.DD")).diff(
      moment(selectDay).add(2, "days"),
      "days"
    );
    if (dayDiff >= 0) {
      for (var j = 0; j < 5; j++) {
        dateList.push(
          moment(selectDay)
            .add(2, "days")
            .subtract(j, "days")
            .format("yyyy.MM.DD")
        );
      }
    } else {
      for (var j = 0; j < 5; j++) {
        dateList.push(
          moment(selectDay)
            .add(2 + dayDiff, "days")
            .subtract(j, "days")
            .format("yyyy.MM.DD")
        );
      }
    }

    let article = await apiUtils.kaciBackendRequest(
      "/article/getArticles/date/?category=" +
        this.props.category +
        "&date=" +
        selectDay
    );

    this.setState({
      article: article,
      loading: false,
      date: dateList,
      currentDate: selectDay,
    });
  }

  searchArticle = (value, e) => {
    this.setState({ searchInput: value });
  };

  filterArticle = () => {
    const filterArticles = this.state.articleOrg.filter((arti) => {
      return arti["article_body"]
        .toLowerCase()
        .includes(this.state.searchInput);
    });
    this.setState({ article: filterArticles });
    if (this.state.searchInput == "") {
      const articleOrg = this.state.articleOrg;
      this.setState({ article: articleOrg });
    }
    this.state.currentDate = "";
  };

  render() {
    const { article, currentPage, date, currentView, PER_PAGE } = this.state;
    const offset = currentPage * PER_PAGE;
    let currentPageData = [];
    if (currentView === "titleView") {
      currentPageData = article
        .slice(offset, offset + PER_PAGE)
        .map((item, index) => (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ArticleCardOnlyTitle data={item}></ArticleCardOnlyTitle>
          </Grid>
        ));
    } else {
      currentPageData = article
        .slice(offset, offset + PER_PAGE)
        .map((item, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <ArticleImageCard data={item}></ArticleImageCard>
          </Grid>
        ));
    }

    const pageCount = Math.ceil(article.length / PER_PAGE);

    const dateChip = date.map((item, index) => (
      <span style={{ margin: 10 + "px" }}>
        <Chip
          size="small"
          label={item}
          color={item === this.state.currentDate ? "secondary" : ""}
          onClick={() => this.handleDateClick(item)}
          variant="outlined"
        />
      </span>
    ));
    if (article.length > 0) {
      return (
        <>
          <div className="searchbar">
            <SearchBar
              value={this.state.searchInput}
              onChange={this.searchArticle}
              onRequestSearch={this.filterArticle}
            />
          </div>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href="#"
              onClick={() => this.handleViewClick("titleView", 15)}
              name={currentView === "titleView" ? "titleSelected" : "title"}
            >
              <DvrIcon /> 제목형
            </Link>
            <Link
              color="inherit"
              href="#"
              onClick={() => this.handleViewClick("imageView", 8)}
              name={currentView === "imageView" ? "imageViewSelected" : ""}
            >
              <ArtTrackIcon /> 사진형
            </Link>
          </Breadcrumbs>
          <Grid className="grid__item--vertical" container spacing={3}>
            {currentPageData}
          </Grid>
          <ReactPaginate
            previousLabel={"이전"}
            nextLabel={"다음"}
            pageCount={pageCount}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
          {dateChip}
        </>
      );
    } else if (!this.state.loading & (article.length === 0)) {
      return (
        <div>
          <div className="searchbar">
            <SearchBar
              value={this.state.searchInput}
              onChange={this.searchArticle}
              onRequestSearch={this.filterArticle}
            />
          </div>
          <h3>표시할 기사가 없습니다. 다른 날짜를 선택해 주세요.</h3>
          {dateChip}
        </div>
      );
    } else {
      return <CircularProgress />;
    }
  }
}

export default Articles;
