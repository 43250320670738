import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import * as apiUtils from "../../utils/apiUtils";
import Articles from "../Articles";
import CSPUpdates from "../CSPUpdates";
import CSPIncidents from "../CSPIncidents";
import CSPBlogs from "../CSPBlogs";
import TechBlogs from "../TechBlogs";
import CSPNotify from "../CSPNotify";
import { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import CSPYoutube from "../CSPYoutube";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import IconUp from "../../static/images/chevron-up.svg";
import IconDown from "../../static/images/chevron-down.svg";

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "100%",
  },
  tabs: {
    minWidth: 180,
    width: 180,
  },
});

function TabPanelSubPart(props) {
  const { children, value, index, ...other } = props;
  // const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      style={{ height: "600px", width: "100%" }}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class TabPanelSub extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      open: true,
      isBlogAccordionUp: false,
      isYoutubeAccordionUp: false,
      isSecurityAccordionUp: false,
      clickedMenu: "",
    };
    this.handleExpand = this.handleExpand.bind(this);
  }

  async componentDidMount() {
    // let categories = await apiUtils.kaciBackendRequest("/category/subCategory?subCategory=" +this.props.category);
    // this.setState({ categories });
  }

  handleExpand(event) {
    let newvalue = !this.state.open;
    this.setState({ open: !this.state.open });
  }

  render() {
    const { classes } = this.props;
    // const { categories,value } = this.state;

    const tabs = [];
    const items = [];

    // sub items
    const BLOG_TABS = [];
    const YOUTUBE_TABS = [];
    const SECURITY_TABS = [];

    const histparam1 = this.props.match.params.id1;
    const histparam2 = this.props.match.params.id2;

    if (this.props.category === "AWS") {
      tabs.push(
        <div className="accordion">
          <Tab
            label="UPDATE"
            value="update"
            component={Link}
            to={`/${histparam1}/update`}
            onClick={() => {
              this.setState({ clickedMenu: "update" });
            }}
            className={
              window.location.href.includes(`/${histparam1}/update`) ||
              this.state.clickedMenu === "update"
                ? "clicked"
                : ""
            }
          />
        </div>
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="update">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPUpdates category="AWS"></CSPUpdates>
          </Container>
        </TabPanelSubPart>
      );

      //////////////////////////////////////
      // accordion식으로 변경

      // Blog tab
      tabs.push(
        <>
          <div className="accordion">
            <Tab
              label="BLOG"
              value="blog"
              // component={Link}
              // to={`/${histparam1}/blog`}
              onClick={() => {
                this.setState({
                  isBlogAccordionUp: !this.state.isBlogAccordionUp,
                });
              }}
              // className={
              //   window.location.href.includes(`/${histparam1}/blog`) ||
              //   this.state.clickedMenu === "blog"
              //     ? "clicked"
              //     : ""
              // }
            />
            <img
              src={this.state.isBlogAccordionUp ? IconUp : IconDown}
              alt={this.state.isBlogAccordionUp ? "up" : "down"}
              onClick={() => {
                this.setState({
                  isBlogAccordionUp: !this.state.isBlogAccordionUp,
                });
              }}
            />
          </div>
          {/* sub items */}
          <div
            className={`tab__sub-item ${
              this.state.isBlogAccordionUp ? "" : "hidden"
            }`}
          >
            {BLOG_TABS}
          </div>
        </>
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="blog">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPBlogs category="AWS"></CSPBlogs>
          </Container>
        </TabPanelSubPart>
      );

      BLOG_TABS.push(
        <Tab
          label="BLOG"
          value="blog"
          component={Link}
          to={`/${histparam1}/blog`}
          onClick={() => {
            this.setState({ clickedMenu: "blog" });
          }}
          className={
            window.location.href.includes(`/${histparam1}/blog`) ||
            this.state.clickedMenu === "blog"
              ? "clicked"
              : ""
          }
        />
      );
      BLOG_TABS.push(
        <Tab
          label="SECURITY BLOG"
          value="security_blog"
          component={Link}
          to={`/${histparam1}/security_blog`}
          onClick={() => {
            this.setState({ clickedMenu: "security_blog" });
          }}
          className={
            window.location.href.includes(`/${histparam1}/security_blog`) ||
            this.state.clickedMenu === "security_blog"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="security_blog">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPBlogs category="AWS Security"></CSPBlogs>
          </Container>
        </TabPanelSubPart>
      );

      BLOG_TABS.push(
        <Tab
          label="ARCHITECTURE BLOG"
          value="architecture_blog"
          component={Link}
          to={`/${histparam1}/architecture_blog`}
          onClick={() => {
            this.setState({ clickedMenu: "architecture_blog" });
          }}
          className={
            window.location.href.includes(`/${histparam1}/architecture_blog`) ||
            this.state.clickedMenu === "architecture_blog"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="architecture_blog">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPBlogs category="AWS Architecture"></CSPBlogs>
          </Container>
        </TabPanelSubPart>
      );

      // tabs.push(
      //     <Accordion>
      //     <AccordionSummary
      //       expandIcon={<ExpandMoreIcon />}  >
      //       <Typography>Blog</Typography>
      //     </AccordionSummary>
      //     <AccordionDetails>
      //     <Tab label='BLOG' value='blog' component={Link} to={`/${histparam1}/blog`}/>
      //     <Tab label='SECURITY BLOG' value='security_blog' component={Link} to={`/${histparam1}/security_blog`}/>
      //     <Tab label='ARCHITECTURE BLOG' value='architecture_blog' component={Link} to={`/${histparam1}/architecture_blog`}/>
      //     </AccordionDetails>
      //   </Accordion>
      // )

      // tabs.push(
      //     <>
      //     <ListItem button onClick={this.handleExpand}>
      //     Blog
      //     {this.state.open ? <ExpandLess /> : <ExpandMore />}
      //   </ListItem>
      //   <Collapse in={this.state.open} timeout="auto" unmountOnExit>
      //   <ListItem>
      //   <Tab label='BLOG' value='blog' component={Link} to={`/${histparam1}/blog`}/>
      //   </ListItem>
      //   <ListItem>
      //      <Tab label='SECURITY BLOG' value='security_blog' component={Link} to={`/${histparam1}/security_blog`}/>
      //      </ListItem>
      //      <ListItem>
      //      <Tab label='ARCHITECTURE BLOG' value='architecture_blog' component={Link} to={`/${histparam1}/architecture_blog`}/>
      //      </ListItem>
      //   </Collapse>
      //   </>
      // )

      /////////////////////////////////////////////////////////////////
      // Youtube tab

      tabs.push(
        <>
          <div className="accordion">
            <Tab
              label="YOUTUBE"
              value="youtube"
              onClick={() => {
                this.setState({
                  isYoutubeAccordionUp: !this.state.isYoutubeAccordionUp,
                });
              }}
            />
            <img
              src={this.state.isYoutubeAccordionUp ? IconUp : IconDown}
              alt={this.state.isYoutubeAccordionUp ? "up" : "down"}
              onClick={() => {
                this.setState({
                  isYoutubeAccordionUp: !this.state.isYoutubeAccordionUp,
                });
              }}
            />
          </div>

          {/* sub items */}
          <div
            className={`tab__sub-item ${
              this.state.isYoutubeAccordionUp ? "" : "hidden"
            }`}
          >
            {YOUTUBE_TABS}
          </div>
        </>
      );

      items.push(
        <TabPanelSubPart value={histparam2} index="youtube">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPYoutube category="AWS Youtube"></CSPYoutube>
          </Container>
        </TabPanelSubPart>
      );

      YOUTUBE_TABS.push(
        <Tab
          label="YOUTUBE"
          value="youtube"
          component={Link}
          to={`/${histparam1}/youtube`}
          onClick={() => {
            this.setState({ clickedMenu: "youtube" });
          }}
          className={
            window.location.href.split("/aws/")[1] === `youtube` ||
            this.state.clickedMenu === "youtube"
              ? "clicked"
              : ""
          }
        />
      );
      YOUTUBE_TABS.push(
        <Tab
          label="YOUTUBE ONLINE TECH TALKS"
          value="youtube_online_tech_talks"
          component={Link}
          to={`/${histparam1}/youtube_online_tech_talks`}
          onClick={() => {
            this.setState({ clickedMenu: "youtube_online_tech_talks" });
          }}
          className={
            window.location.href.includes(
              `/${histparam1}/youtube_online_tech_talks`
            ) || this.state.clickedMenu === "youtube_online_tech_talks"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="youtube_online_tech_talks">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPYoutube category="AWS Youtube Online Tech Talks"></CSPYoutube>
          </Container>
        </TabPanelSubPart>
      );

      // ////////////////////////////////////////
      // Security tab

      tabs.push(
        <>
          <div className="accordion">
            <Tab
              label="SECURITY NOTIFY"
              value="security_notify"
              onClick={() => {
                this.setState({
                  isSecurityAccordionUp: !this.state.isSecurityAccordionUp,
                });
              }}
            />
            <img
              src={this.state.isSecurityAccordionUp ? IconUp : IconDown}
              alt={this.state.isSecurityAccordionUp ? "up" : "down"}
              onClick={() => {
                this.setState({
                  isSecurityAccordionUp: !this.state.isSecurityAccordionUp,
                });
              }}
            />
          </div>
          {/* sub items */}
          <div
            className={`tab__sub-item ${
              this.state.isSecurityAccordionUp ? "" : "hidden"
            }`}
          >
            {SECURITY_TABS}
          </div>
        </>
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="security_notify">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPNotify category="AWS Security Notify"></CSPNotify>
          </Container>
        </TabPanelSubPart>
      );

      SECURITY_TABS.push(
        <Tab
          label="SECURITY NOTIFY"
          value="security_notify"
          component={Link}
          to={`/${histparam1}/security_notify`}
          onClick={() => {
            this.setState({ clickedMenu: "security_notify" });
          }}
          className={
            window.location.href.includes(`/${histparam1}/security_notify`) ||
            this.state.clickedMenu === "security_notify"
              ? "clicked"
              : ""
          }
        />
      );
      SECURITY_TABS.push(
        <Tab
          label="LINUX SECURITY NOTIFY"
          value="linux_seucurity_notify"
          component={Link}
          to={`/${histparam1}/linux_seucurity_notify`}
          onClick={() => {
            this.setState({ clickedMenu: "linux_seucurity_notify" });
          }}
          className={
            window.location.href.includes(
              `/${histparam1}/linux_seucurity_notify`
            ) || this.state.clickedMenu === "linux_seucurity_notify"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="linux_seucurity_notify">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPNotify category="AWS Linux Security Notify"></CSPNotify>
          </Container>
        </TabPanelSubPart>
      );

      SECURITY_TABS.push(
        <Tab
          label="LINUX 2 SECURITY NOTIFY"
          value="linux_2_seucurity_notify"
          component={Link}
          to={`/${histparam1}/linux_2_seucurity_notify`}
          onClick={() => {
            this.setState({ clickedMenu: "linux_2_seucurity_notify" });
          }}
          className={
            window.location.href.includes(
              `/${histparam1}/linux_2_seucurity_notify`
            ) || this.state.clickedMenu === "linux_2_seucurity_notify"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="linux_2_seucurity_notify">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPNotify category="AWS Linux 2 Security Notify"></CSPNotify>
          </Container>
        </TabPanelSubPart>
      );

      /////////////////////////// GCP
    } else if (this.props.category === "GCP") {
      tabs.push(
        <Tab
          label="UPDATE"
          value="update"
          component={Link}
          to={`/${histparam1}/update`}
          onClick={() => {
            this.setState({ clickedMenu: "update" });
          }}
          className={
            window.location.href.includes("gcp/update") ||
            this.state.clickedMenu === "update"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="update">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPUpdates category="GCP"></CSPUpdates>
          </Container>
        </TabPanelSubPart>
      );

      tabs.push(
        <Tab
          label="INCIDENT"
          value="incident"
          component={Link}
          to={`/${histparam1}/incident`}
          onClick={() => {
            this.setState({ clickedMenu: "incident" });
          }}
          className={
            window.location.href.includes("gcp/incident") ||
            this.state.clickedMenu === "incident"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="incident">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPIncidents category="GCP"></CSPIncidents>
          </Container>
        </TabPanelSubPart>
      );

      tabs.push(
        <Tab
          label="BLOG"
          value="blog"
          component={Link}
          to={`/${histparam1}/blog`}
          onClick={() => {
            this.setState({ clickedMenu: "blog" });
          }}
          className={
            window.location.href.includes("gcp/blog") ||
            this.state.clickedMenu === "blog"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="blog">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPBlogs category="GCP"></CSPBlogs>
          </Container>
        </TabPanelSubPart>
      );

      tabs.push(
        <>
          <div className="accordion">
            <Tab
              label="YOUTUBE"
              value="youtube"
              onClick={() => {
                this.setState({
                  isYoutubeAccordionUp: !this.state.isYoutubeAccordionUp,
                });
              }}
            />
            <img
              src={this.state.isYoutubeAccordionUp ? IconUp : IconDown}
              alt={this.state.isYoutubeAccordionUp ? "up" : "down"}
              onClick={() => {
                this.setState({
                  isYoutubeAccordionUp: !this.state.isYoutubeAccordionUp,
                });
              }}
            />
          </div>
          {/* sub items */}
          <div
            className={`tab__sub-item ${
              this.state.isYoutubeAccordionUp ? "" : "hidden"
            }`}
          >
            {YOUTUBE_TABS}
          </div>
        </>
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="youtube">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPYoutube category="GCP Youtube"></CSPYoutube>
          </Container>
        </TabPanelSubPart>
      );

      YOUTUBE_TABS.push(
        <Tab
          label="YOUTUBE"
          value="youtube"
          component={Link}
          to={`/${histparam1}/youtube`}
          onClick={() => {
            this.setState({ clickedMenu: "youtube" });
          }}
          className={
            window.location.href.includes("gcp/youtube") ||
            this.state.clickedMenu === "youtube"
              ? "clicked"
              : ""
          }
        />
      );
      YOUTUBE_TABS.push(
        <Tab
          label="TECH YOUTUBE"
          value="tech_youtube"
          component={Link}
          to={`/${histparam1}/tech_youtube`}
          onClick={() => {
            this.setState({ clickedMenu: "tech_youtube" });
          }}
          className={
            window.location.href.includes(`/${histparam1}/tech_youtube`) ||
            this.state.clickedMenu === "tech_youtube"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="tech_youtube">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPYoutube category="GCP Tech Youtube"></CSPYoutube>
          </Container>
        </TabPanelSubPart>
      );

      /////////////////////////// Azure
    } else if (this.props.category === "Azure") {
      tabs.push(
        <Tab
          label="UPDATE"
          value="update"
          component={Link}
          to={`/${histparam1}/update`}
          onClick={() => {
            this.setState({ clickedMenu: "update" });
          }}
          className={
            window.location.href.includes("azure/update") ||
            this.state.clickedMenu === "update"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="update">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPUpdates category="Azure"></CSPUpdates>
          </Container>
        </TabPanelSubPart>
      );

      // tabs.push(<Tab label='INCIDENT' value={1} component={Link} to={`/${histparam1}/1`}/>)
      // items.push(<TabPanelSubPart value={histparam2} index={1}>
      //     <Container style={{minWidth: '100%', margin: 'auto'}}>
      //       <CSPIncidents category='Azure'></CSPIncidents>
      //     </Container>
      //   </TabPanelSubPart>)

      tabs.push(
        <Tab
          label="BLOG"
          value="blog"
          component={Link}
          to={`/${histparam1}/blog`}
          onClick={() => {
            this.setState({ clickedMenu: "blog" });
          }}
          className={
            window.location.href.includes("azure/blog") ||
            this.state.clickedMenu === "blog"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="blog">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPBlogs category="Azure"></CSPBlogs>
          </Container>
        </TabPanelSubPart>
      );

      tabs.push(
        <Tab
          label="YOUTUBE"
          value="youtube"
          component={Link}
          to={`/${histparam1}/youtube`}
          onClick={() => {
            this.setState({ clickedMenu: "youtube" });
          }}
          className={
            window.location.href.includes("azure/youtube") ||
            this.state.clickedMenu === "youtube"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="youtube">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPYoutube category="Azure Youtube"></CSPYoutube>
          </Container>
        </TabPanelSubPart>
      );

      /////////////////////////// NCP
    } else if (this.props.category === "NCP") {
      tabs.push(
        <Tab
          label="UPDATE"
          value="update"
          component={Link}
          to={`/${histparam1}/update`}
          onClick={() => {
            this.setState({ clickedMenu: "update" });
          }}
          className={
            window.location.href.includes("ncp/update") ||
            this.state.clickedMenu === "update"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="update">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPUpdates category="NCP"></CSPUpdates>
          </Container>
        </TabPanelSubPart>
      );

      tabs.push(
        <Tab
          label="INCIDENT"
          value="incident"
          component={Link}
          to={`/${histparam1}/incident`}
          onClick={() => {
            this.setState({ clickedMenu: "incident" });
          }}
          className={
            window.location.href.includes("ncp/incident") ||
            this.state.clickedMenu === "incident"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="incident">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPIncidents category="NCP"></CSPIncidents>
          </Container>
        </TabPanelSubPart>
      );

      tabs.push(
        <>
          <div className="accordion">
            <Tab
              label="BLOG"
              value="blog"
              onClick={() => {
                this.setState({
                  isBlogAccordionUp: !this.state.isBlogAccordionUp,
                });
              }}
            />
            <img
              src={this.state.isBlogAccordionUp ? IconUp : IconDown}
              alt={this.state.isBlogAccordionUp ? "up" : "down"}
              onClick={() => {
                this.setState({
                  isBlogAccordionUp: !this.state.isBlogAccordionUp,
                });
              }}
            />
          </div>
          {/* sub items */}
          <div
            className={`tab__sub-item ${
              this.state.isBlogAccordionUp ? "" : "hidden"
            }`}
          >
            {BLOG_TABS}
          </div>
        </>
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="blog">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPBlogs category="NCP"></CSPBlogs>
          </Container>
        </TabPanelSubPart>
      );

      BLOG_TABS.push(
        <Tab
          label="BLOG"
          value="blog"
          component={Link}
          to={`/${histparam1}/blog`}
          onClick={() => {
            this.setState({ clickedMenu: "blog" });
          }}
          className={
            window.location.href.includes("ncp/blog") ||
            this.state.clickedMenu === "blog"
              ? "clicked"
              : ""
          }
        />
      );
      BLOG_TABS.push(
        <Tab
          label="TECH BLOG"
          value="tech_blog"
          component={Link}
          to={`/${histparam1}/tech_blog`}
          onClick={() => {
            this.setState({ clickedMenu: "tech_blog" });
          }}
          className={
            window.location.href.includes(`/${histparam1}/tech_blog`) ||
            this.state.clickedMenu === "tech_blog"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="tech_blog">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPBlogs category="NCP Tech"></CSPBlogs>
          </Container>
        </TabPanelSubPart>
      );

      tabs.push(
        <Tab
          label="YOUTUBE"
          value="youtube"
          component={Link}
          to={`/${histparam1}/youtube`}
          onClick={() => {
            this.setState({ clickedMenu: "youtube" });
          }}
          className={
            window.location.href.includes("ncp/youtube") ||
            this.state.clickedMenu === "youtube"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="youtube">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPYoutube category="NCP Youtube"></CSPYoutube>
          </Container>
        </TabPanelSubPart>
      );

      tabs.push(
        <Tab
          label="SECURITY NOTIFY"
          value="security_notify"
          component={Link}
          to={`/${histparam1}/security_notify`}
          onClick={() => {
            this.setState({ clickedMenu: "security_notify" });
          }}
          className={
            window.location.href.includes("ncp/security_notify") ||
            this.state.clickedMenu === "security_notify"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="security_notify">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <CSPNotify category="NCP Security Notify"></CSPNotify>
          </Container>
        </TabPanelSubPart>
      );

      /////////////////////////// Tech
    } else if (this.props.category === "Tech") {
      tabs.push(
        <Tab
          label="BLOG"
          value="blog"
          component={Link}
          to={`/${histparam1}/blog`}
          onClick={() => {
            this.setState({ clickedMenu: "blog" });
          }}
          className={
            window.location.href.includes("tech/blog") ||
            this.state.clickedMenu === "blog"
              ? "clicked"
              : ""
          }
        />
      );
      items.push(
        <TabPanelSubPart value={histparam2} index="blog">
          <Container style={{ minWidth: "100%", margin: "auto" }}>
            <TechBlogs category="tech"></TechBlogs>
          </Container>
        </TabPanelSubPart>
      );
    }

    return (
      <div className={`${classes.root} side-nav`}>
        <Tabs
          value={histparam2}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          orientation="vertical"
          className={`${classes.tabs} side-nav__items`}
        >
          {tabs}
        </Tabs>
        {items}
      </div>
    );
  }
}

TabPanelSub.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(useStyles)(TabPanelSub));
