import React from "react";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";

/*
  {
    "article_href": "https://www.youtube.com/watch?v=dxF8dLpM5as",
    "article_title": "S1 E8 | Autonomous & AI-Powered:  A Look at Frameworks & Training with Amazon ML Solutions Lab",
    "article_image": "https://i1.ytimg.com/vi/dxF8dLpM5as/hqdefault.jpg",
    "category": "AWS Youtube",
    "articleDate": "2021-06-30 14:00:30+00:00",
    "description": "How does machine learning (ML) help solve some of the automotive industry’s biggest challenges? In this episode, our industry expert hosts, Dean and Stefano, are joined by Suchitra Sathyanarayana from the ML Solutions Lab, a team of cross-functional specialists that deploys and scales ML concepts and models to get them ready for production. Hear about aspects of autonomous driving that ML is helping improve, such as 3D object detection, semantic segmentation, multi-sensor fusion, through the lens of frameworks and training.\n\nSubscribe: \nMore AWS videos - http://bit.ly/2O3zS75 \nMore AWS events videos - http://bit.ly/316g9t4\n\nABOUT AWS\nAmazon Web Services (AWS) is the world’s most comprehensive and broadly adopted cloud platform, offering over 200 fully featured services from data centers globally. Millions of customers — including the fastest-growing startups, largest enterprises, and leading government agencies — are using AWS to lower costs, become more agile, and innovate faster.\n\n#AWS #AmazonWebServices #CloudComputing",
    "insertion_date": "Wed Jul 07 10:26:15 UTC 2021",
    "article_source": "AWS Youtube",
    "articleTag": "AWS_YOUTUBE_UPDATE",
    "lan": "en"
  },
*/

const dataJson = {
  id: "",
  article_image: "",
  insertion_date: "",
  category: "",
  article_title: "",
  article_href: "",
  article_source: "",
  description: "",
};
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    textAlign: "left",
  },
  media: {
    height: 0,
    paddingTop: "36.25%", // 16:9
  },

  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  let data = props.data ? props.data : dataJson;

  let content = "";
  if (data.description.length > 300) {
    content = data.description.substring(0, 300);
  } else {
    content = data.description;
  }

  let image_url = "";
  if (data.article_image == null) {
    image_url =
      "https://www.playnetwebhosting.com/wp-content/plugins/slider-hero-pro/images/noimage.png";
  } else {
    image_url = data.article_image;
  }

  return (
    <Card className={classes.root}>
      <CardHeader title={data.article_title} subheader={data.articleDate} />
      <CardMedia
        className={classes.media}
        image={image_url}
        title="thumbnail"
      />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {content.concat("....")}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton
          onClick={() => {
            axios({
              method: "post",
              //url: 'http://localhost:8080/pubsub/article',
              url: global.config.endpoint + "/pubsub/article",
              data: data,
            });

            window.open(data.article_href, "_blank");
          }}
          aria-label="add to favorites"
          style={{ color: "#A50034" }}
        >
          <ExitToAppIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
